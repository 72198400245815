/**
 * Notifications
 */
const NotificationsNode = document.querySelector('#notifications')
class Notifications {
  constructor({ title, content, type }) {
    this.title = title
    this.content = content
    this.type = type
  }

  create() {
    // Create Notification
    const Notification = document.createElement('div')
    Notification.classList.add('notifications-item', 'alert')
    NotificationsNode.appendChild(Notification)

    // Add type if needed
    if (this.type) Notification.classList.add(`alert-${this.type}`)

    // Create Title
    if (this.title) {
      const NotificationTitle = document.createElement('h4')
      NotificationTitle.innerHTML = this.title
      Notification.appendChild(NotificationTitle)
    }
    // Create Content
    const NotificationContent = document.createElement('p')
    NotificationContent.innerHTML = this.content
    Notification.appendChild(NotificationContent)

    this.node = Notification
  }

  show() {
    this.create()

    setTimeout(() => {
      this.node.classList.add('shown')
    }, 50)

    setTimeout(() => {
      this.closing()
    }, 5050)
  }

  closing() {
    this.node.classList.remove('show')
    setTimeout(() => {
      this.node.remove()
    }, 550)
  }
}

export default Notifications
